import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import actionConst from './addressTriggerConst';
import { IDismissTriggerBody, IRCalRating, IRiskControl, ITriggerRisk } from 'constants/commonExportedInterfaces';
import { get, patch, post, put } from 'utils';
import { apiUrlDev, assessmentUnitsUrl, controlsUrl, risksUrl, triggersUrl } from 'constants/apiConstants';

export const getRisksData = createAsyncThunk(actionConst.risksData, async (id: string, { rejectWithValue }) => {
    try {
        const response = await get({
            baseURL: `${triggersUrl}/${id}/risks`,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const addNewRisk = createAsyncThunk(actionConst.addNewRisk, async (risk: any, { rejectWithValue }) => {
    try {
        const response = await post({
            baseURL: risksUrl,
            data: risk,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const editRisk = createAsyncThunk(actionConst.editRisk, async (risk: ITriggerRisk, { rejectWithValue }) => {
    try {
        const response = await put({
            baseURL: `${risksUrl}/${risk.riskId}`,
            data: risk,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

// Get risk taxonomi, impact and likelihood catalogues
export const getRiskTaxonomies = createAsyncThunk(actionConst.riskTaxonomies, async (_, { rejectWithValue }) => {
    try {
        const response = await get({
            baseURL: `${apiUrlDev}/risk-taxonomies`,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const getRiskImpacts = createAsyncThunk(actionConst.riskImpacts, async (_, { rejectWithValue }) => {
    try {
        const response = await get({
            baseURL: `${apiUrlDev}/risk-impacts`,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const getRiskLikelihoods = createAsyncThunk(actionConst.riskLikelihoods, async (_, { rejectWithValue }) => {
    try {
        const response = await get({
            baseURL: `${apiUrlDev}/risk-likelihoods`,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const getProcesses = createAsyncThunk(actionConst.riskProcesses, async (_, { rejectWithValue }) => {
    try {
        const response = await get({
            baseURL: `${apiUrlDev}/processes`,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

// Get control taxonomy catalogue
export const getControlTaxonomies = createAsyncThunk(actionConst.controlTaxonomies, async (_, { rejectWithValue }) => {
    try {
        const response = await get({
            baseURL: `${apiUrlDev}/control-taxonomies`,
        });

        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

// Get control assessment calculation
export const getControlAssessment = createAsyncThunk(
    actionConst.controlAssessment,
    async (body: { control: IRiskControl }, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${apiUrlDev}/controls/assessment`,
                data: body,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const addNewControl = createAsyncThunk(
    actionConst.addNewControl,
    async (control: IRiskControl, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: controlsUrl,
                data: control,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const editControl = createAsyncThunk(
    actionConst.editControl,
    async (control: IRiskControl, { rejectWithValue }) => {
        try {
            const response = await put({
                baseURL: `${controlsUrl}/${control.controlId}`,
                data: control,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getRiskRating = createAsyncThunk(
    actionConst.riskCalRating,
    async (body: IRCalRating, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${apiUrlDev}/inherit-risk-ratings/calculate`,
                data: body,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const mapControlsToRisk = createAsyncThunk(
    actionConst.mapControlsToRisk,
    async (risk: ITriggerRisk, { rejectWithValue }) => {
        try {
            const response = await put({
                baseURL: `${risksUrl}/${risk.riskId}`,
                data: risk,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getAssessmentUnitControls = createAsyncThunk(
    actionConst.assessmentUnitControls,
    async (assessmentUnitId: string, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${assessmentUnitsUrl}/${assessmentUnitId}/controls`,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const updateTriggerStatus = createAsyncThunk(
    actionConst.updateTriggerStatus,
    async (trigger: IDismissTriggerBody, { rejectWithValue }) => {
        try {
            const response = await patch({
                baseURL: `${triggersUrl}/${trigger.triggerId}`,
                data: trigger,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const addressRisk = createAction<ITriggerRisk>(actionConst.addressRisk);
export const clearAddressedRisks = createAction(actionConst.clearAddressedRisks);
export const clearControlAssessmentData = createAction(actionConst.clearControlAssessmentData);
export const clearNotification = createAction(actionConst.clearNotification);
export const clearRisksData = createAction(actionConst.clearRisksData);
export const updateActiveStep = createAction<string>(actionConst.activeStep);
export const updateOutstandingRisks = createAction<ITriggerRisk[]>(actionConst.updateOutstandingRisks);
export const updateFlaggedRisks = createAction<ITriggerRisk[] | []>(actionConst.flaggedRisks);
export const submitTrigger = createAction(actionConst.submitTrigger);
export const updateUserRole = createAction<string>(actionConst.updateUserRole);
export const flaggedRiskSelected = createAction<ITriggerRisk>(actionConst.flaggedRisks);
