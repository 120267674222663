export const apiCall = `${process.env.REACT_APP_BASE_URL}/api`;
export const tableApiUrl = `${apiCall}/sessions`;
export const riskControlTestDataUrl = `${apiCall}/test-data-tables`;
export const riskControlUrl = `${apiCall}/risk-control-testcase-combinations`;
export const sectorApiUrl = `${apiCall}/sectors`;
export const lobApiUrl = `${apiCall}/lobs?sector_id=`;
export const processApiUrl = `${apiCall}/processes?lob_id=`;
export const subProcessApiUrl = `${apiCall}/processes?parent_process_id=`;
export const viewResultsUrl = `${apiCall}/test-results`;

export const apiUrlDev = 'https://nexus4risk-backend-tbrcsa-dev.sbp.eyclienthub.com/api';
// export const apiUrlDev = 'http://localhost:8080/api';
export const assessmentUnitsUrl = `${apiUrlDev}/assessment-units`;
export const triggersUrl = `${apiUrlDev}/triggers`;
export const risksUrl = `${apiUrlDev}/risks`;
export const controlsUrl = `${apiUrlDev}/controls`;
export const resetUrl = `${apiUrlDev}/demo/reset-test-data`;
